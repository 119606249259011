@media (max-width: 660px) {
  #box_logo_cadastro {
    display: none;
  }
  #containeSteppers {
    width: 100%;
    align-items: center;
  }
  #gridSteppers {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .form-signup {
    width: 100%;
  }
}
@media (min-width: 660px) {
  #Logo_Cadastro_Style {
    display: none;
  }
}
@media (max-width: 420px) {
  #containeSteppers {
    height: auto;
  }
}
@media (max-height: 500px) {
  #gridSteppers {
    margin-top: auto;
  }
  #containeSteppers {
    height: auto;
  }
}
