@media (max-width: 970px) {
  #banner {
    display: none;
  }
  #containeSteppers {
    width: 100%;
    align-items: center;
  }
  #gridSteppers {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .form-signup {
    width: 100%;
  }
}
@media (min-width: 970px) {
  #breakPoint {
    display: none;
  }
}
@media (max-width: 420px) {
  #containeSteppers {
    height: auto;
  }
}
@media (max-height: 500px) {
  #containeSteppers {
    height: auto;
  }
}
