#label_cadastro {
  text-align: center;
  font-family: "Livvic", sans-serif;
  text-transform: lowercase;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 20px;
}
li {
  font-family: "Livvic", sans-serif;
  font-size: 15px;
}
p {
  font-size: 20px;
}
